<template>
   <v-row
      class="fill-height">
    <v-col
        md="8"
        class="pt-10 pr-4 content-height overflow-y-auto">
			<span class="body-text text-primary letter-spacing-normal">
				{{ pkg.data.package.description }}
			</span>
      <p class="subtitle-1 grey--text mt-12">Channel Spread</p>
      <v-row class="">

      <v-col cols="5" class="channels">
        <v-card class="channel pa-2 d-flex align-center" outlined>
          <v-avatar width="32px" height="32px" min-width="32px" tile color="#0085ff" class="avatar"><span class="white--text">1</span></v-avatar>
          <div class="channel-info d-flex justify-center flex-column ml-2">
            <span class="subtitle-2 font-weight-bold">Staging</span>
            <span class="date grey--text">PLACEHOLDER DAYS AGO</span>
          </div>
        </v-card>
        <v-card class="channel pa-2 d-flex align-center my-2" outlined>
          <v-avatar width="32px" height="32px" min-width="32px" tile color="#0085ff" class="avatar"><span class="white--text">1</span></v-avatar>
          <div class="channel-info d-flex justify-center flex-column ml-2">
            <span class="subtitle-2 font-weight-bold">Beta</span>
            <span class="date grey--text">18 days ago</span>
          </div>
        </v-card>
        <v-card class="channel pa-2 d-flex align-center my-2" outlined>
          <v-avatar width="32px" height="32px" min-width="32px" tile color="#00a86b" class="avatar"><span class="white--text">1</span></v-avatar>
          <div class="channel-info d-flex justify-center flex-column ml-2">
            <span class="subtitle-2 font-weight-bold">Stable</span>
            <span class="date grey--text">PLACEHOLDER DAYS AGO</span>
          </div>
        </v-card>
        <v-card class="channel pa-2 d-flex align-center my-2" outlined>
          <v-avatar width="32px" height="32px" min-width="32px" tile color="#e63462" class="avatar"><span class="white--text">1</span></v-avatar>
          <div class="channel-info d-flex justify-center flex-column ml-2">
            <span class="subtitle-2 font-weight-bold">VIP</span>
            <span class="date grey--text">PLACEHOLDER DAYS AGO</span>
          </div>
        </v-card>
      </v-col>
      </v-row>
    </v-col>
    <v-divider
    style="z-index: 1 !important; height: 100vh;"
        class="d-none d-md-flex"
        vertical/>
    <v-col
        cols="12"
        sm="12"
        md="4"
        class="ml-n3 py-0 pr-0 content-height overflow-y-auto">
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Package Name</span>
          <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
            {{ pkg.data.package.name }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Distribution</span>
          <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
            {{ pkg.data.package.distribution }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Version</span>
          <v-chip
              small
              style="border-radius: 4px !important; height: 16px;"
              text-color="white"
              color="#0085ff">
              {{pkg.data.package.version}}
          </v-chip>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Built</span>
          <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
            {{ formattedBuildTime }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Package Size</span>
          <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
            {{ pkg.data.package.size_package }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <v-divider/>
      <v-card
          height="72"
          class="px-4 pb-5 pt-3"
          flat>
        <v-card-text class="pa-0">
          <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">URL</span>
          <v-list-item-title class="subtitle-2 text--primary font-weight-regular">
            {{ pkg.data.package.url }}
          </v-list-item-title>
        </v-card-text>
      </v-card>
      <divider/>
    </v-col>
   </v-row>
</template>

<script>
import moment from "moment";
export default {
  props: ["pkg"],
  computed: {
    formattedBuildTime() {
      return moment(this.pkg.data.package.build_time).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
}
</script>

<style lang="scss" scoped>
.channel {
  border-radius: 4px;
}
.avatar {
  border-radius: 4px !important;
}
.date {
  font-size: 12px;
}
</style>
