<template>
  <v-dialog
    v-model="$route.params.package"
    no-click-animation
    scrollable
    overlay-color="#2c3039"
    overlay-opacity="0.3"
    transition="slide-x-reverse-transition"
    max-width="1080"
    @click:outside="closeDialog"
  >
    <v-card
      flat
      tile
      class="mr-sm-15 overflow-md-y-hidden overflow-x-hidden px-4 px-md-12 pt-8 pb-0"
    >
      <PackageInfo
        v-if="type === 'packages' && $route.params.package"
        type="location"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import PackageInfo from "../components/PackageInfo";
import { mapActions } from "vuex";

export default {
  name: "FlyoutMenu",
  props: ["type"],
  components: {
    PackageInfo
  },
  mounted() {
    if (this.$route.params.package ) {
      this.selectItem((this.type || 'package'), this.$route.params.id, this.$route.params.version);
    }
  },
  data: () => ({
    dialog: null//need to set it to true so it will work no shit braniac
  }),
  methods: {
    ...mapActions(["getPackage"]),
    closeDialog() {
      if (this.$route.params.package) {
        return this.$router.replace('/repositories/'+this.$route.params.id)
      } else {
        return this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {

.v-dialog--scrollable {
  position: absolute;
  right: 0;
  margin: 0 !important;
  border-radius: 0;
}

.v-dialog:not(.v-dialog--fullscreen) {
  min-height: 100%;
  box-shadow: none;
}
}

.mr-sm-15 {
  margin-right: 64px;
}

@media screen and (max-width: 600px) {
  .mr-sm-15 {
    margin-right: 0 !important;
  }
}

@media screen and (min-width: 600px) {
  .overflow-md-y-hidden {
    overflow-y: hidden;
  }

  .content-height {
    height: calc(80vh + 5px);
  }
}
</style>
