<template>
  <div style="z-index:999 !important;" class="alert--bottom-fixed d-flex justify-center ml-n3 px-2">
    <v-alert prominent
             :value="alert.shown"
             :type="alert.type || 'success'">
      <v-row align="center">
        <v-col class="grow">
          {{ alert.message }}
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
export default {
  computed: {
    alert() {
      return this.$store.getters.getAlert;
    },
  },
  data: () => ({
    timer: null,
  }),
  watch: {
    alert: {
      handler(val) {
        if (val.shown === true && !val.constant) {
          clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.$store.dispatch('updateAlertVisibility', false);
          }, 3000);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped
       lang="scss">

</style>
