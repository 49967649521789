<template>
	<v-list
			v-if="selectedItem"
			class="pt-1 pb-1">
		<v-list-item class="px-0">
			<v-list-item-avatar
					class="ma-0 border-radius-4 mr-4 mb-4"
					tile
					color="#0085ff"
					size="64"
					>
				<div class="package-icon">
					<v-icon dark>mdi-star
					</v-icon>
				</div>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title class="float-right headline font-weight-bold text-primary">
					{{selectedItem.package_name}}
				</v-list-item-title>
				<v-list-item-subtitle
						v-if="selectedItem.id"
						class="text-secondary">
					{{selectedItem.id}}
				</v-list-item-subtitle>
			</v-list-item-content>
			<v-list-item-action>
				<v-col class="pa-0">
					<v-menu
							offset-y
							left
							v-if="type">
						<template v-slot:activator="{ on }">
							<v-btn
									class="mr-2"
									icon
									v-on="on"
							>
								<v-icon class="text-primary">mdi-dots-vertical</v-icon>
							</v-btn>
						</template>
						<v-list
								dense
								width="224px">
							<v-list-item
									v-for="(item, i) in actionItems"
									:key="i"
									class="v-list-hover"
									@click="openModal(`device-${item.title.toLowerCase()}`)"
							>
								<v-list-item-icon class="pr-0 mr-4">
									<v-icon class="text-secondary">{{ item.icon }}</v-icon>
								</v-list-item-icon>
								<v-list-item-title class="pr-4 font-weight-regular text-primary">
									{{ item.title }}
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-btn
							@click="closeDialog()"
							icon>
						<v-icon class="text-primary">mdi-close
						</v-icon>
					</v-btn>
				</v-col>
			</v-list-item-action>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
    name: 'FlyoutMenuHead',
    props: ['selectedItem', 'type', 'route'],
    data() {
        return {
            actionItems: [{
                title: 'Edit',
                icon: 'mdi-pencil',
            }, {
                title: 'Delete',
                icon: 'mdi-delete',
            }],
            modals: {
                'device-delete': false,
                'device-archive': false,
            },
        };
    },
    methods: {
        closeDialog() {
            return this.$router.go(-1);
        },

        avatarName(string) {
            var matches = string.match(/\b(\w)/g);
            return matches.join('');
        },

        randomColor() {
            var colors = ['red', 'pink', 'purple', 'indigo', 'blue', 'cyan', 'teal', 'green', 'lime', 'orange', 'blue-grey'];
            return colors[Math.floor(Math.random() * colors.length)];
        },
    },
};
</script>

<style scoped>
	.package-icon {
		padding: 20px;
		background-color: var(--v-lightGrey-base);
		width: 64px !important;
		height: 64px !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.border-radius-4 {
		border-radius: 4px !important;
	}

</style>
