<template>
  <v-app v-if="this.$route.name !== 'Login'" :style="{background: $vuetify.theme.themes[theme].background}">
    <Alert />
    <v-progress-linear indeterminate v-if="loading" class="progress_bar"></v-progress-linear>
    <LeftSidebar></LeftSidebar>
    <v-main class="main-container">
      <div
        class="row-fixed pt-4 pl-4 pb-6  mt-n1 grey--text text--darken-3 font-weight-bold white">
        <div class="bord-btm" v-if="this.$route.name === 'Packages' && selectedRepo">
          <router-link
            to="/repositories"
            class="grey--text text-decoration-none"
            >Repositories</router-link
          >
          / {{ selectedRepo.display_name || selectedRepo.name }}
          
        </div>
        <span v-else-if="this.$route.name === 'package'">{{ this.$route.params.package }}</span>
        <span v-else>{{ this.$route.name }}</span> 
      </div>
      <v-col class="px-0 py-0 botom-line">
        <v-tabs 
        v-show="this.$route.name === 'Settings'" 
        class="bottom-line" 
        :value="tabs.indexOf($route.params.tab)"
        active-class="selected-tab">
            <v-tab 
              @click="selectTab('users')"
              class="tab-text px-6" 
              to="/settings/users">
              {{ this.tab[0].name }}
            </v-tab>
          <v-tab 
            class="tab-text px-6" 
            to="/settings/groups"
            @click="selectTab('groups')"
            >
            {{this.tab[1].name}}
          </v-tab>
          <v-tab  
          class="tab-textpx-6" 
          to="/settings/content_type"
          @click="selectTab('content_type')"
          >
            {{ this.tab[2].name}}
          </v-tab>
        </v-tabs>
      </v-col>
      <router-view ></router-view>
    </v-main>
    <RightSidebar></RightSidebar>
  </v-app>
  <v-app v-else>
    <v-progress-linear indeterminate v-if="loading" style="z-index:999 !important; "></v-progress-linear>
    <router-view></router-view>
  </v-app>
</template>

<script>
import LeftSidebar from "@/components/LeftSidebar";
import RightSidebar from '@/components/RightSidebar';
import Alert from '@/components/Alert';

export default {
  computed: {
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    selectedRepo() {
      return this.$store.getters.selectedRepo;
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  name: "App",
  components: {
    LeftSidebar,
    RightSidebar,
    Alert
  },
  data: () => ({
   repo: null,
   selectedTab: 'users',//selected tab on oppening the device info,
    tabs: ['users', 'groups', 'content_type'],
    tab: [
      {
        name: 'Users',
      },
      {
        name: 'Groups',
        
      },
      {
        name: 'Content Type',
      },
    ]
  }),
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
      this.$router.replace({params: {tab: tab}})
    },
  },
  mounted(){
    if (localStorage.repository) {
      this.repo = localStorage.repository;
      this.$store.commit('SET_REPOSITORY', this.repo);
    }else{
      this.$store.commit('SET_REPOSITORY', 'pulp3');
    }
  }
};
</script>

<style lang="scss">
.main-container{
  background-color: #ffffff;
}

.title {
  margin-top: 22px !important;
}

body {
  height: 100vh;
}


.main {
  max-height: 100vh;
}
.header-router{
  background-color: #f1f1f1;
}
.progress_bar {
  position: fixed;
  top: 0px;
  left: 100px;
  width: 91.5%;
  z-index: 999;
}

@media only screen and (max-width: 600px) {
  .container-padding {
    padding: 0 !important;
  }
}
.v-overlay {
  left: 100px !important;
  right: 64px !important;
}
.alert--bottom-fixed {
  width: 100%;
  bottom: 0px;
  position: fixed !important;
  left: 0px;
}
.selected-tab {
  color: black !important;
  
}
.bottom-line{
  border-bottom: 1px solid #DEDEDE !important;
}
.bottom-line .tab-text{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.25px;
}

::-webkit-scrollbar{
  width: 0;
}



</style>
