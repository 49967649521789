<template>
  <v-row class="fill-height">
    <v-col cols="12" sm="12" md="4" class="pa-4">
      <v-list dense>
        <v-list-item-group mandatory v-model="item">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            active-class="text-secondary"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-secondary font-weight-regular"
                v-text="item.text"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-divider class="d-none d-md-flex" vertical></v-divider>
    <v-col  cols="12" sm="12" md="8" class="ml-n1 pl-4 pt-2 fill-height" v-if="item === 1">
      <v-row>
        <v-col cols="6" md="6" sm="12">
          <v-card outlined class="pa-2 d-flex align-center">
            <v-avatar
              size="32"
              color="#0085ff"
              style="border-radius: 4px !important;"
              tile
              ><v-icon color="white" size="16">mdi-star</v-icon></v-avatar
            >
            <div class="package-info ml-2 d-flex justify-center flex-column">
              <span class="subtitle-2">python36</span>
              <div class="date grey--text">bogami neka</div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6" md="6" sm="12">
          <v-card outlined class="pa-2 d-flex align-center">
            <v-avatar
              size="32"
              color="#0085ff"
              style="border-radius: 4px !important;"
              tile
              ><v-icon color="white" size="16">mdi-star</v-icon></v-avatar
            >
            <div class="package-info ml-2 d-flex justify-center flex-column">
              <span class="subtitle-2">python36</span>
              <div class="date grey--text">bogami neka</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col  cols="12" sm="12" md="8" class="ml-n1 pl-4 pt-2 fill-height" v-if="item === 0">
      <v-row>
        <!-- <v-col cols="6" md="6" sm="12" v-for="version in olderVersions" v-bind:key="version.version">
          <v-card outlined class="pa-2 d-flex align-center">
            <v-avatar
              size="32"
              color="#0085ff"
              style="border-radius: 4px !important;"
              tile
              ><v-icon color="white" size="16">mdi-star</v-icon></v-avatar
            >
            <div class="package-info ml-2 d-flex justify-center flex-column">
              <span class="subtitle-2">{{version.name}}</span>
              <div class="date grey--text">{{version.version}}</div>
            </div>
          </v-card>
        </v-col> -->
        <v-col cols="6" md="6" sm="12" v-for="item in otherVersions" :key="item.version">
          <v-card outlined class="pa-2 d-flex align-center" @click="openVersion(item)">
            <v-avatar
              size="32"
              color="#0085ff"
              style="border-radius: 4px !important;"
              tile
              ><v-icon color="white" size="16">mdi-star</v-icon></v-avatar
            >
            <div class="package-info ml-2 d-flex justify-center flex-column">
              <span class="subtitle-2">{{item.package_name}}</span>
              <div class="date grey--text">{{ item.version }}</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Elevator from '../services/Elevator';
export default {
  props: ["pkg"],
  computed: {
    otherVersions() {
      return this.olderVersions.filter(x => x.version !== this.$props.pkg.version)
    }
  },
  watch: {
    pkg: function(val) {
      console.log(val)
    }
  },
  methods: {
    openVersion(item) {
      let repo = this.$route.path.split('/')[2]
      this.$router.push(`/repositories/${repo}/${item.package_name}/${item.version}`)
    },
  },
  created() {
    let repo = this.$route.path.split('/')[2]
    Elevator.getPackage(repo, this.$props.pkg.package_name, "").then(response => {
      this.olderVersions = response.data;
    });
  },
  data: () => ({
    item: 0,
    olderVersions: [],
    items: [{ text: "Other Versions" }, { text: "Dependencies" }],
  }),
};
</script>

<style scoped lang="scss">
.date {
  font-size: 12px;
}
</style>
