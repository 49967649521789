<template>
  <div class="fill-height">
    <v-row
      v-if="!getSelectedPackage || loading"
      class="text-center fill-height"
      align="center"
    >
      <v-col>
        <v-progress-circular color="primary" indeterminate size="48">
        </v-progress-circular>
      </v-col>
    </v-row>
    <template v-else>
      <v-row>
        <v-col>
          <FlyoutMenuHead
            :selectedItem="getSelectedPackage"
            :type="type"
            route="packages"
          />
        </v-col>
      </v-row>
      <v-row style="border-bottom: 1px solid #F5F5F5;" class="pl-0">
        <v-tabs
          v-if="getSelectedPackage"
          class="px-3 d-block transparent"
          active-class="selected-tab"
        >
          <v-tab class="tab px-6 transparent" @click="selectTab('general')">
            General
          </v-tab>
          <v-tab class="tab px-6" @click="selectTab('details')">
            Details
          </v-tab>
        </v-tabs>
      </v-row>
      <PackageInfoGeneral
        v-if="selectedTab === 'general'"
        :pkg="getSelectedPackage"
      />
      <PackageInfoDetails
        v-if="selectedTab === 'details'"
        :pkg="getSelectedPackage" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlyoutMenuHead from "../components/FlyoutMenuHead";
import PackageInfoGeneral from "./PackageInfoGeneral";
import PackageInfoDetails from "./PackageInfoDetails";
export default {
  props: ["package", "type"],
  name: "PackageInfo",
  components: {
    FlyoutMenuHead,
    PackageInfoGeneral,
    PackageInfoDetails
  },
  data: () => ({
    selectedTab: "general",
  }),
  computed: {
    ...mapGetters(['getSelectedPackage', 'loading']),
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.package-icon {
  padding: 20px;
  background-color: var(--v-lightGrey-base);
  border-radius: 5px;
  width: 64px !important;
  height: 64px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep {
  .theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent !important;
  }

  .v-tab {
    letter-spacing: normal;
  }

  div.v-slide-group__prev.v-slide-group__prev--disabled {
    display: none !important;
  }
}

.selected-tab {
  color: var(--v-textColor-base);
}
</style>
