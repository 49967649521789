import Vue from 'vue'
import Vuex from 'vuex'
import Elevator from '@/services/Elevator'
import {TokenStorage} from '@/services/TokenStorage';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auditsMetadata: {},
    selectedUsers: [],
    selectedTypes: [],
    permissions: [],
    currentUser: {},
    isShown: false,
    userData: false,
    repositories: [],
    groups: [],
    currentGroup: null,
    repository: window.localStorage.getItem("repository") || "pulp3",
    loading: false,
    selectedRepository: null,
    selectedPackage: null,
    audits: [],
    auditTypes: [],
    auditUsers: [],
    alert: {
      type: "",
      message: "",
      shown: false,
    }
  },
  mutations: {
    SET_REPOSITORIES(state, payload) {
      //sort array of objects payload.data by name
      payload.data.sort(function(a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
      state.repositories = payload.data;
    },
    SET_SELECTED_REPO(state, payload) {
      state.selectedRepository = payload;
    },
    SET_SELECTED_PACKAGE(state, payload) {
      state.selectedPackage = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_GROUPS(state, payload) {
      state.groups = payload;
    },
    SET_ALERT_VISIBLE(state, value) {
      state.alert.shown = value;
    },
    SET_ALERT(state, payload) {
      state.alert = payload;
    },
    SET_REPOSITORY(state, payload) {
      state.repository = payload;
    },
    SET_AUDIT_LOGS(state, payload) {
      state.audits = payload;
    },
    SET_AUDIT_USERS(state, payload) {
      state.auditUsers = payload;
    },
    SET_AUDIT_TYPES(state, payload) {
      state.auditTypes = payload;
    },
    setUserForm(state, payload) {
      state.isShown = payload;
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
    SET_PERMISSIONS(state, payload) {
      state.permissions = payload;
    },
    SET_CURRENT_GROUP(state, payload) {
      state.currentGroup = payload;
    },
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },
    PUSH_USER(state, payload) {
      state.auditUsers.push(payload);
    },
    SET_SELECTED_USERS(state, payload) {
      state.selectedUsers = payload;
    },
    SET_SELECTED_TYPES(state, payload) {
      state.selectedTypes = payload;
    },
    SET_AUDITS_METADATA(state, payload) {
      state.auditsMetadata = payload;
    }
  },
  getters: {
    getPermissions(state){
      return state.permissions;
    },
    getAuditMetadata(state) {
      return state.auditsMetadata;
    },
    getAlert(state) {
      return state.alert
    },
    getAudits(state) {
      return state.audits
    },
    getAuditTypes(state) {
      return state.auditTypes
    },
    getAuditUsers(state) {
      return state.auditUsers
    },
    getRepos(state) {
      return state.repositories
    },
    token(state) {
      return state.token
    },
    groups(state) {
      return state.groups
    },
    loading(state){
      return state.loading
    },
    selectedRepo(state) {
      return state.selectedRepository
    },
    getSelectedPackage(state) {
      return state.selectedPackage
    },
    getRepository(state) {
      return state.repository
    },
    getData(state) {
      return state.isShown;
    },
    getUserData(state) {
      return state.userData;
    },
    getCurrentGroup(state) {
      return state.currentGroup;
    },
    getCurrentUser(state) {
      return state.currentUser;
    }
  },
  actions: {
    getCurrentUser({commit}, id) {
      commit('SET_LOADING', true);
      return new Promise((resolve, reject) => {
        Elevator.getCurrentUser(id).then((response) => {
          commit('SET_LOADING', false);
          commit('SET_CURRENT_USER' , response.data);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getPermissionsData({commit}) {
      return new Promise((resolve, reject) => {
        Elevator.getPermissions().then((response) => {
          commit('SET_PERMISSIONS', response.data);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getSelectedGroup({commit}, name) {
      commit('SET_LOADING', true);
      return new Promise((resolve, reject) => {
        Elevator.getGroup(name).then((response) => {
          console.log(response.data)
          commit('SET_CURRENT_GROUP', response.data);
          resolve(response);
        }).catch((error) => {
          reject(error);
        });
      });
    },
    updateRepository({commit}, data) {
      window.localStorage.setItem("repository", data);
      commit('SET_REPOSITORY', data);
      commit('SET_ALERT', {
        type: 'success',
        message: 'Repository changed to ' + data,
        shown: true,
      });
    },
    updateAlert({commit}, data) {
      commit('SET_ALERT', data);
    },
    updateAlertVisibility({commit}, value) {
      commit('SET_ALERT_VISIBLE', value);
    },
    login({commit}, data) {
      return new Promise((resolve, reject) => {
        console.log(data)
        commit('SET_LOADING', true);
        Elevator.login(data).then(response => {
          console.log(response.data.tokens)
          TokenStorage.storeToken(response.data.tokens.access_token)
          TokenStorage.storeRefreshToken(response.data.tokens.refresh_token)
          commit('SET_LOADING', false);
          resolve();
        }).catch(err => {
          commit('SET_LOADING', false);
          reject(err);
        });
      });
    },
    fetchAuditLogs({commit}, id) {
      return new Promise((resolve, reject) => {
        Elevator.getAuditLogs(id).then(response => {
          console.log(response.data)
          commit('SET_AUDITS_METADATA', {
            page: response.data.data.page,
            total: response.data.data.total_pages
        })
            commit('SET_AUDIT_LOGS', response.data.data.rows)
          resolve(true);
        }).catch(err => {
          console.log(err);
          reject(err);
        }).finally(() => {
        });
      })
    },
    getGroups({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true);
        Elevator.getGroups().then(response => {
          commit('SET_LOADING', false);
          if (response) {
            commit('SET_GROUPS', response.data);
          }
          resolve();
        }).catch(err => {
          console.log(err);
          commit('SET_LOADING', false);
          reject(err);
        });
      });
    },
    setSelectedRepo({commit}, item) {
      commit('SET_SELECTED_REPO', item)
    },
    setSelectedPackage({commit}, item) {
      return new Promise((resolve, reject) => {
        Elevator.getPackage(item.repo, item.name, item.version, item.release).then(response => {
          commit('SET_SELECTED_PACKAGE', response.data);
          resolve();
        }
        ).catch(err => {
          console.log(err);
          reject(err);
        }
        ).finally(() => {
        }
        );
      });

    },
    getRepositories({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true);
        Elevator.getRepositories().then(response => {
          console.log(response.data)
          commit('SET_LOADING', false);
          if (response.data) {
            commit('SET_REPOSITORIES', response.data);
          }
          resolve();
        }).catch(err => {
          console.log('ERror')
          console.log(err);
          commit('SET_LOADING', false);
          reject(err);
        });
      });
    },
    getPackage({commit}, data) {
      return new Promise((resolve, reject) => {
        const { repo, name, version, release } = data;
        commit('SET_LOADING', true);
        commit('SET_SELECTED_PACKAGE', {});
        Elevator.getPackage(repo, name, version, release).then(response => {
          commit('SET_LOADING', false);
          if (response) {
            commit('SET_SELECTED_PACKAGE', response.data);
          }
          resolve();
        }).catch(err => {
          commit('SET_LOADING', false);
          reject(err);
        })
      })
    },
    getActors({commit}) {
      return new Promise((resolve, reject) => {
        commit('SET_LOADING', true);
        Elevator.getUsers().then(response => {
          commit('SET_LOADING', false);
          if (response) {
            commit('SET_AUDIT_USERS', response.data);
          }
          resolve();
        }).catch(err => {
          console.log(err);
          commit('SET_LOADING', false);
          reject(err);
        });
      });
    },
    selectedUsers({commit}, data) {
      commit('SET_SELECTED_USERS', data)
    },
    selectedTypes({commit}, data) {
      commit('SET_SELECTED_TYPES', data)
    }
  },


  modules: {
  }
})
