import Vue from "vue";
import VueRouter from "vue-router";
import FlyoutMenu from "../components/FlyoutMenu";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/repositories"
  },
  {
    path: "/settings/:tab",
    name: "Settings",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/Settings.vue"
      ),
    props: true,
    children: [
      {
        path: ":action",
        name: "SettingsAction",
        component: FlyoutMenu,
        props: false,
      },
    ],
  },
  {
    path: "/repositories",
    name: "Repositories",
    component: () =>
      import(
        /* webpackChunkName: "repositories" */ "../views/Repositories.vue"
      ),
    props: true,
  },
  {
    path: "/repositories/:id",
    name: "Packages",
    component: () =>
      import(/* webpackChunkName: "Packages" */ "../views/Packages.vue"),
    children: [
      {
        path: ":package/:version?",
        name: "package",
        component: FlyoutMenu,
        props: false,
      },
    ],
  },
  {
    path: "/channels",
    name: "Channels",
    component: () =>
      import(/* webpackChunkName: "channels" */ "../views/Channels.vue"),
  },
  {
    path: "/audit",
    name: "Audit Log",
    component: () =>
      import(/* webpackChunkName: "audit" */ "../views/AuditLog.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
