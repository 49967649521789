<template>
	<v-navigation-drawer
			app
			color="#0085ff"
			width="100"
			dark
			mobile-breakpoint="600"
	>
		<div class="d-none d-sm-flex justify-center ma-4">
			<router-link
					style="height: 48px;"
					to="/">
				<img
						height="48"
						src="../assets/elevator.png"
						alt="logo"/>
			</router-link>
		</div>
		<v-list class="pa-0">
			<v-list-item
					v-for="item in items"
					:key="item.title"
					link
					router
					:to="item.route"
					active-class="nav-bg-color"
			>
				<v-list-item-content>
					<v-icon>
						{{ item.icon }}
					</v-icon>
					<v-list-item-title class="caption font-weight-medium text-center">
						{{ item.title }}
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
        <v-list class="pa-0 bottom-items">
			<v-list-item
					v-for="item in bottomItems"
					:key="item.title"
					link
					router
					:to="item.route"
			>
				<v-list-item-content>
					<v-icon>
						{{ item.icon }}
					</v-icon>
					<v-list-item-title class="caption font-weight-medium text-center">
						{{ item.title }}
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
    data() {
        return {
            items: [{
                title: 'Repositories',
                icon: 'mdi-folder-star',
                route: '/repositories'
            },
            {
                title: 'Channels',
                icon: 'mdi-clipboard-arrow-right',
                route: '/channels'
            },
            {
                title: 'Audit Log',
                icon: 'mdi-file-search',
                route: '/audit'   
            }],
            bottomItems: [{
                title: 'Help',
                icon: 'mdi-help-box',
                route: '/help'
            },
            {
                title: 'Settings',
                icon: 'mdi-cog',
                route: `/settings/users`,
            }
			
			]
        };
    },
};
</script>

<style lang="scss">
.bottom-items {
    position: absolute !important;
    bottom: 0;
}
</style>
