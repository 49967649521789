import axios from 'axios';
import router from '../router';
import store from '../store';
import {TokenStorage} from './TokenStorage';
const axiosApiInstance = axios.create()
axiosApiInstance.defaults.baseURL = process.env.VUE_APP_API_URL;
axiosApiInstance.interceptors.request.use((config) => {
  const auth = TokenStorage.isAuthenticated();
  if (!auth && config.url !== '/auth/login') {
    router.replace({name: 'Login'})
    throw new axios.Cancel("Missing auth");
  }
  config.headers = TokenStorage.getAuthentication().headers;
  return config;
})
axiosApiInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
  if (error.message !== "Missing auth") {
    if (error.response.status === 422) {
      TokenStorage.clear();
      router.push({ name: 'Login' })
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
            reject(error);
        });
    }

    if (error.config.url == '/auth/refresh') {
      TokenStorage.clear();
      router.push({ name: 'Login' })
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    return TokenStorage.getNewToken().then((token) => {
      const config = error.config;
      config.headers['Authorization'] = `Bearer ${token}`
      return new Promise((resolve, reject) => {
        axiosApiInstance.request(config).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    }).catch(error => {
      Promise.reject(error);
    })
  } else {
    Promise.reject(error);
  }

})
export default {
    getRepositories() {
        return axiosApiInstance.get(`repositories/${store.getters.getRepository}`)
    },
    getPackages(id) {
        return axiosApiInstance.get(`repositories/${store.getters.getRepository}/${id}`)
    },
    getPackage(repo, name, version, release) {
        return axiosApiInstance.get(`repositories/${store.getters.getRepository}/${repo}/${name}?version=${version}&release=${release}`)
    },
    getGroup(name) {
        return axiosApiInstance.get(`groups/${store.getters.getRepository}/${name}`)
    },//get current group
    sendUpdate(data) {
        return axiosApiInstance.post(`packages/${store.getters.getRepository}/copy`, data)
    },
    getAuditLogs(data, type) {
      if(!data && !type) {
        return axiosApiInstance.post(`/audit`, {"actors":[]})
      }else{
        return axiosApiInstance.post('/audit', data, type)// to filter audits need to send data in object format {types: ["Access"], actors: [3,4]}
      }
    },
    login(data) {
        return axiosApiInstance.post(`/auth/login`, {
            "google_token": data
        })
    },
    refresh() {
        return axiosApiInstance.post(`/refresh`)
    },
    getGroups() {
        return axiosApiInstance.get(`groups/${store.getters.getRepository}`)
    },
    getUsers(){
      return axiosApiInstance.get(`/auth/actors`)
    },
    getPermissions(){
      return axiosApiInstance.get(`/auth/permission`)
    },
    addUser(email, admin){
      return axiosApiInstance.post(`/auth/user`, {email: email, admin: admin})
    },
    deleteUser(id){
      return axiosApiInstance.post(`/auth/delete-user` ,{email: id})
    },
    addPermissions(email, permission){
      return axiosApiInstance.post(`/auth/permission`, {user_email: email, groups_name: permission})
    }, 
    deletePermissions(email, permission){
      return axiosApiInstance.post(`/auth/remove-permission`, {user_email: email, groups_name: permission})
    },
    getCurrentUser(id){
      return axiosApiInstance.get(`/auth/user/${id}`)
    },
    addGroup(name, type, repos){
      return axiosApiInstance.post(`/groups`, {name: name, group_type: type, repositories: repos})
    },
    addRepoGroup(name, repos){
      return axiosApiInstance.post(`/groups/addrepo`, {group_name: name, repositories: repos})
    },
    removeRepoGroup(name, repos){
      return axiosApiInstance.post(`/groups/remove-repo`, {group_name:name, repo_name: repos})
    },
    deleteGroup(name){
      return axiosApiInstance.delete(`/groups/${name}`)
    }




}

