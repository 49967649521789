<template>
	<v-navigation-drawer
			app
			color="blackish"
			width="64"
			dark
			right
			mobile-breakpoint="600"
	>
		<v-list
				dense
				nav
				class="py-1"
		>
			<v-list-item class="px-1">
				<v-list-item-avatar>
					<img
							src="../assets/avatar.png"
							alt="avatar"/>
				</v-list-item-avatar>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
    data() {
        return {
            item: 0,
            items: [
                {
                    title: 'Directory',
                    icon: 'mdi-folder',
                },
                {
                    title: 'Assets',
                    icon: 'mdi-briefcase',
                },
            ],
        };
    },
};
</script>

<style scoped>
</style>
